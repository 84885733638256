/* 
Template Name: Cotido - Tailwind CSS Coming Soon Template
Version: 1.0.0
Author: Zoyothemes
Email: zoyothemes@gmail.com
File: Main Css File
*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
/*********************************/
/*             General           */
/*===============================*/
html {
  @apply relative scroll-smooth;
}

body {
  @apply font-bevietnampro text-[15px] text-black;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  @apply font-bevietnampro leading-normal font-semibold;
}

p {
  @apply leading-relaxed;
  @apply font-bevietnampro;
}

::selection {
  @apply bg-indigo-600/90 text-white;
}

/*********************************/
/*             Helper            */
/*===============================*/
.container,
.contacinr-fluid {
  @apply relative;
}

.text-type-element {
  @apply z-2 after:content-[""] after:absolute after:h-1 after:w-auto after:bg-orange-300 after:right-0 after:bottom-0 after:left-0 after:z-0 after:rounded-md after:transition-all after:duration-300 after:ease-in-out;
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}