
/*********************************/
/*             Helper            */
/*===============================*/
.container,
.contacinr-fluid {
  @apply relative;
}

.text-type-element {
  @apply z-2 after:content-[''] after:absolute after:h-1 after:w-auto after:bg-orange-300 after:right-0 after:bottom-0 after:left-0 after:z-0 after:rounded-md after:transition-all after:duration-300 after:ease-in-out;
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
  }
}