/*********************************/
/*             General           */
/*===============================*/
html {
    @apply relative scroll-smooth;
}

body {
    @apply font-bevietnampro text-[15px] text-black;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    @apply font-bevietnampro leading-normal font-semibold;
}

p {
    @apply leading-relaxed;
    @apply font-bevietnampro;
}

::selection {
    @apply bg-indigo-600/90 text-white;
}